.header{
  margin-left: 2rem;
  font-size: 4rem;
}

.class-header {
  margin-left: 3rem;
  font-size: 1rem;
}

.teaching-image {
  margin: 1rem;
  border-radius: .2rem;
  flex-direction: row;
}

.teaching-image:hover {
  cursor: grab;
}

.teaching-modal-image {
  max-height: 125rem;
  height: auto;
  width: auto;
  max-width: 130rem;
  min-width: 25rem;
  margin: 3rem;
  border-radius: .2rem;
}

.teaching-image-container {
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
}

.modal-container {
  align-items: center;
  flex-direction: column;
  display: flex;
}

.modal-90w {
  width: auto;
  max-width: 130rem;
  max-height: 135rem;
}

.modal-header{
  font-size: 3rem;
}
.class-name {
  margin-left: 3rem;
  font-size: 1rem;
}

.teaching-thumbnail-image {
  margin: 1rem;
  border-radius: .3rem;
}

.teaching-thumbnail-container {
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 10rem;
  margin-bottom: 7rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}

.teaching-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (max-width: 2900px) {
  .header{
    font-size: 4rem;
  }

  .teahcing-image {
    max-height: 1000px;
    max-width: 1000px;
  }
  
  .teaching-image:hover {
    cursor: grab;
  }
  
  .teaching-modal-image {
    max-height: 105rem;
    max-width: 110rem;
    min-width: 39rem;
    margin: 2rem;
  }

  .modal-90w {
    max-width: 110rem;
    max-height: 115rem;
  }

  .modal-header{
    font-size: 2rem;
  }
}

@media screen and (max-width: 2400px) {
  .header{
    font-size: 3rem;
  }

  .teahcing-image {
    max-height: 800px;
    max-width: 800px;
  }

  .teaching-modal-image {
    max-height: 80rem;
    max-width: 85rem;
    min-width: 39rem;
    margin: 1rem;
  }

  .modal-90w {
    max-width: 85rem;
    max-height: 90rem;
  }

  .modal-header{
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1900px) {
  .header{
    font-size: 2rem;
  }

  .teaching-image {
    max-height: 600px;
    max-width: 600px;
  }

  .teaching-modal-image {
    max-height: 60rem;
    max-width: 65rem;
    min-width: 19rem;
    margin: .05rem;
  }

  .modal-90w {
    max-width: 70rem;
    max-height: 70rem;
  }

  .modal-header{
    font-size: 1rem;
  }

  .teaching-image-container {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 1400px) {
  .header{
    font-size: 1.5rem;
  }

  /* .image {
    max-height: 400px;
    max-width: 400px;
  } */
  
  .teaching-modal-image {
    max-height: 45rem;
    max-width: 40rem;
    min-width: 19rem;
  }

  .modal-90w {
    max-width: 45rem;
    max-height: 50rem;
  }

  .teaching-thumbnail-image {
    max-height: 200px;
    max-width: 200px;
  }
}

@media screen and (max-width: 750px) {
  .header{
    font-size: 1rem;
  }

  .teaching-image {
    max-height: 200px;
    max-width: 200px;
    margin: .5rem;
  }

  .teaching-modal-image {
    max-height: 800px;
    max-width: 500px;
  }

  .modal-90w {
    max-height: 550px;
    height: auto;
    width: auto;
    max-width: 550px;
  }
}

@media screen and (max-width: 550px) {

  .teaching-modal-image {
    max-width: 400px;
  }

  .modal-90w {
    max-height: 420px;
    max-width: 420px;
  }
}

@media screen and (max-width: 420px) {
  .teaching-modal-image {
    max-width: 300px;
    margin: .2rem;
  }

  .teaching-image {
    max-height: 150px;
    max-width: 150px;
    margin: .5rem;
  }

  .class-name {
    margin-left: .5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .teaching-thumbnail-image {
    max-height: 150px;
    max-width: 150px;
  }

  .teaching-thumbnail-container {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .teaching-image-container {
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 5rem;
  }
}
