@import url('https://fonts.googleapis.com/css?family=Gelasio');

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
}

.nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home {
  font-family: 'Gelasio', serif;
  font-size: x-large;
  color: rgb(54, 53, 53);
  font-weight: bold;
}

a.heading.nav-link:focus {
  color: grey;
}

a.nav-link:hover {
  color: grey;
}

a.nav-link:focus {
  color: grey;
}

a.nav-link {
  color: rgb(54, 53, 53);
  font-size: 3.5rem;
}

a.heading.nav-link {
  color: rgb(54, 53, 53);
  font-size: 3rem;
}

@media screen and (max-width: 2800px) {
  .app-container {
    font-size: 1rem;
  }

  a.nav-link {
    font-size: 1.8rem;
  }
  
  a.heading.nav-link {
    font-size: 1.2rem;
  }
} 
