.header{
  margin-left: 2rem;
  font-size: 4rem;
}

.image {
  max-height: 1200px;
  height: auto;
  width: auto;
  max-width: 1200px;
  margin: 1rem;
  border-radius: .2rem;
}

.image:hover {
  cursor: grab;
}

.video {
  max-height: 1200px;
  max-width: 1200px;
  margin: 2rem;
  border-radius: .2rem;
  position: static;
  width: 1200px;
  height: 900px;
}

.image-container {
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.modal-image {
  max-height: 125rem;
  height: auto;
  width: auto;
  max-width: 130rem;
  min-width: 25rem;
  margin: 3rem;
  border-radius: .2rem;
}

.modal-container {
  align-items: center;
  flex-direction: column;
  display: flex;
}

.modal-90w {
  width: auto;
  max-width: 130rem;
  max-height: 135rem;
}

.modal-header{
  font-size: 3rem;
}

.class-name {
  margin-bottom: 5rem;
}
.thumbnail-image {
  height: 600px;
  width: auto;
  margin: 1rem;
  border-radius: .3rem;
}

.portfolio-image-container {
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 7rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 2900px) {
  .header{
    font-size: 4rem;
  }

  .image {
    max-height: 1000px;
    max-width: 1000px;
  }

  .image:hover {
    cursor: grab;
  }
  
  .video {
    max-height: 1000px;
    max-width: 1000px;
    width: 1000px;
    height: 700px;
  }

  .modal-image {
    max-height: 105rem;
    max-width: 110rem;
    min-width: 39rem;
    margin: 2rem;
  }

  .modal-90w {
    max-width: 110rem;
    max-height: 115rem;
  }

  .modal-header{
    font-size: 2rem;
  }
}

@media screen and (max-width: 2400px) {
  .header{
    font-size: 3rem;
  }

  .image {
    max-height: 800px;
    max-width: 800px;
  }
  
  .video {
    max-height: 800px;
    max-width: 800px;
    width: 800px;
    height: 600px;
  }

  .modal-image {
    max-height: 80rem;
    max-width: 85rem;
    min-width: 39rem;
    margin: 1rem;
  }

  .modal-90w {
    max-width: 85rem;
    max-height: 90rem;
  }

  .modal-header{
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1900px) {
  .header{
    font-size: 2rem;
  }

  .image {
    max-height: 600px;
    max-width: 600px;
  }
  
  .video {
    max-height: 600px;
    max-width: 600px;
    width: 600px;
    height: 400px;
  }

  .modal-image {
    max-height: 60rem;
    max-width: 65rem;
    min-width: 19rem;
    margin: .05rem;
  }

  .modal-90w {
    max-width: 70rem;
    max-height: 70rem;
  }

  .modal-header{
    font-size: 1rem;
  }

  .thumbnail-image {
    height: 300px;
  }

  .portfolio-image-container {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 1400px) {
  .header{
    font-size: 1.5rem;
  }

  .image {
    max-height: 400px;
    max-width: 400px;
  }
  
  .video {
    max-height: 400px;
    max-width: 400px;
    width: 400px;
    height: 250px;
  }

  .modal-image {
    max-height: 45rem;
    max-width: 40rem;
    min-width: 19rem;
  }

  .modal-90w {
    max-width: 45rem;
    max-height: 50rem;
  }

  .thumbnail-image {
    height: 200px;
  }
}

@media screen and (max-width: 750px) {
  .header{
    font-size: 1rem;
  }

  .image {
    max-height: 300px;
    max-width: 300px;
    margin: .5rem;
  }

  .modal-image {
    max-height: 800px;
    max-width: 500px;
  }

  .modal-90w {
    max-height: 550px;
    height: auto;
    width: auto;
    max-width: 550px;
  }
}

@media screen and (max-width: 550px) {

  .modal-image {
    max-width: 400px;
  }

  .modal-90w {
    max-height: 420px;
    max-width: 420px;
  }

  .video {
    position: static;
    width: 300px;
    height: 165px;
  }
}

@media screen and (max-width: 420px) {
  .modal-image {
    max-height: 450px;
    max-width: 300px;
    margin: .2rem;
  }

  .image {
    max-height: 300px;
    max-width: 300px;
    margin: .5rem;
  }

  .portfolio-image-container {
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 5rem;
  }

  .image-container {
    margin-bottom: 5rem;
  }
}
