.bio-container {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bio-body {
  padding: 1rem;
  width: 50%;
  font-size: 3.5rem;
}

.bio-header {
  padding-left: 2rem;
  font-size: 4.5rem;
}

.bio-image {
  height: 40rem;
  width: auto;
  padding: 1rem;
  border-radius: .3rem;
}

.bio-image-container {
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 2800px) {

  .bio-header {
    padding-left: 2rem;
    font-size: 3.5rem;
  }

  .bio-image {
    height: 30rem;
  }

  .bio-body {
    padding: 1rem;
    width: 50%;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 2000px) {

  .bio-header {
    padding-left: 2rem;
    font-size: 2rem;
  }

  .bio-image {
    height: 20rem;
  }

  .bio-body {
    padding: 1rem;
    width: 50%;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {

  .bio-body {
    padding: 1rem;
    width: 80%;
  }
}

@media screen and (max-width: 775px) {
  .bio-header {
    padding-left: 2rem;
    font-size: 1.5rem;
  }

  .bio-container {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1rem;
  }

  .bio-body {
    padding: .5rem;
    width: 100%;
    font-size: 1rem;
  }
}

