
.heading {
  color: rgb(36, 35, 35);
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  background-color: white;
}

.nav-container {
  display: flex;
  flex-direction: row;
}

