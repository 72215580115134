.cv-header {
  padding-left: 2rem;
  font-size: 4rem;
}

.cv-heading {
  font-family: 'Gelasio', serif;
  font-weight: bold;
  font-size: 3.6rem;
}

.cv-container {
  padding-bottom: 7rem;
}

.cv-body {
  display: flex;
  flex-direction: column;
}

.cv-item {
  padding-left: 10rem;
  padding-bottom: .5rem;
  font-size: 3rem;
}

.item {
  display: flex;
  padding-right: .5rem;
}

.space {
  padding-bottom: .5rem;
}

.bold {
  font-weight: bold;
}

.indent {
  padding-left: 2rem;
}

.container {
  display: flex;
  flex-flow: row wrap;
}

.pdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
}

.pdf-icon {
  color: black;
  z-index: 3;
}

.year {
  flex-direction: column;
  padding-right: 5rem;
}

@media screen and (max-width: 2800px) {
  .cv-header {
    font-size: 3rem;
  }
  
  .cv-heading {
    font-size: 2.5rem;
  }

  .cv-item {
    font-size: 2rem;
  }

  .pdf {
    font-size: 3rem;
  }

}

@media screen and (max-width: 2000px) {
  .cv-header {
    font-size: 2rem;
  }
  
  .cv-heading {
    font-size: 1.8rem;
  }

  .cv-item {
    font-size: 1.5rem;
    padding-left: 5rem;
  }

  .pdf {
    font-size: 2rem;
  }

  .cv-container {
    padding-bottom: 4rem;
  }

}

@media screen and (max-width: 600px) {
  .cv-header {
    font-size: 1.5rem;
  }

  .cv-item {
    padding-left: 1rem;
    font-size: 1rem;
  }

  .cv-heading {
    padding-left: 1rem;
    font-size: 1.2rem;
  }

  .container {
    display: flex;
    flex-flow: column wrap;
  }

  .item {
    padding-left: 1rem;
  }

  .pdf {
    font-size: 1rem;
  }
}

