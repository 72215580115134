
.home-container {
  margin-bottom: 3rem;
  width: 100%;
  min-height: 10rem; 
  max-height: 100rem; 
  background-size: 100%; 
  position: fixed;
  z-index: 0;
  opacity: 0.9;
}

.home-image-container {
  width: 70%;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: space-around; 
}

.home-image {
  width: auto;
  height: 100%;
  min-height: 10rem; 
  max-height: 100rem; 
  background-size: 100%; 
  box-sizing: border-box;
  position: fixed;
  z-index: 0;
  opacity: 1;
  padding: 1rem;
  border-radius: 2rem;
}

.large-home-image {
  width: auto;
  height: 70%;
  min-height: 70rem; 
  max-height: 100rem;  
  background-size: 50%; 
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  opacity: 0.7;
  border-radius: 2rem;
}

.small-home-image {
  width: auto;
  height: 50%;
  min-height: 10rem; 
  max-height: 70rem; 
  background-size: 50%; 
  box-sizing: border-box;
  position: fixed;
  z-index: 2;
  opacity: 0.8;
  padding: 1rem;
  border-radius: 2rem;
}

@media screen and (max-width: 2400px) {
  .large-home-image {
    min-height: 10rem; 
    max-height: 70rem; 
  }
}

@media screen and (max-width: 1400px) {
  .large-home-image {
    width: auto;
    height: 30rem;
    min-height: 10rem; 
    max-height: 35rem; 
    background-size: 100%; 
  }
}

@media screen and (max-width: 930px) {
  .home-image-container {
    width: 40%;
    margin:1;
    display: flex;
    justify-content: center; 
  }

  .home-image {
    height: 65%;
  }

  .large-home-image {
    height: 30rem;
    margin: 1;
  }

  .small-home-image {
    width: auto;
    height: 50%;
  }
}

@media screen and (max-width: 600px) {
  .home-image-container {
    justify-content: center; 
  }

  .home-image {
    height: 65%;
  }

  .large-home-image {
    min-height: 200px; 
    max-height: 500px; 
    height: auto;
  }
}

@media screen and (max-width: 420px) {
  .home-image-container {
    justify-content: flex-start; 
  }

  .home-image {
    height: 55%;
  }

  .large-home-image {
    max-height: 500px; 
    height: auto;
  }

  .small-home-image {
    width: auto;
    height: 35%;
  }
}

@media screen and (max-width: 325px) {
  .home-image {
    height: 45%;
  }
} 


